<template>
  <div class="forgot-password">
      <div class="forgot-password-section">
          <div class="content">
            <router-link tag="div" to="/security" class="head">
            <h1><span class="green large-text-head">Linkmeup </span></h1>
            <small class="small-text-head"><b>Security</b></small>
            </router-link>

            <div class="cen">
                <h3>Forgot Password</h3>
            </div>

            <div class="image cen">
                <img src="@/assets/images/verify.png" alt="pin">
            </div>

            <div class="rec-msg cen">
                Please kindly enter your registered email to recover your password
            </div>

            <div>
                <form @submit.prevent="sendRequest">
                    <div>
                        <label>Email address</label>
                        <div>
                            <input type="email" v-model="formData.email" placeholder="nonsogodfrey@gmail.com">
                        </div>
                        <div class="bottom cen">
                            <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '80%', borderRadius: '5px' }" text="Reset Password"/>
                        </div>
                    </div>
                </form>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import Button from '../../components/Button.vue'
import axios from 'axios'

export default {
    components: {
        Button
    },
    data() {
        return {
            formData: {
                email: ''
            }
        }
    },
    methods: {
        sendRequest() {
            axios.post(`${this.$store.state.auth.apiUrl}/api/Secondary/Account/forgot-password`, {
                emailAddress: this.formData.email
            })
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>
.forgot-password {
    padding: 30px 20px;
    height: 100%;
}

.content h1 {
    /* margin-bottom: 1rem; */
    text-align: center;
}

.green {
    color: #25a244de;
}
  
.head {
    color: #25a244de;
    text-align: center;
    cursor: pointer;
    margin-bottom: 1rem;
    display: block;
    text-decoration: none;
}

.content > div, .content > a {
    margin-bottom: 20px;
}

.cen {
    text-align: center;
}

.rec-msg {
    font-size: 14px;
    color: #222222;
}

form {
    display: grid;
    place-items: center;
}

.form label {
    text-align: left;
}

form input {
    margin-top: 2px;
    border: 1px solid #a7a7a7;
    font-size: 15px;  
    width: 340px;
}

.bottom {
    margin-top: 70px;
    padding: 0 2rem;
}
</style>